import { Container } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'
import { initializeApollo } from '../lib/apolloClient'
import { HomePageModule } from '../src/components/Modules'
import NotFound from '../src/components/Modules/NotFound'
// import { HomePageModule } from "../src/components/Modules";
import Layout from '../src/constants/Layout'
import { AppContextWrapper, useAppContext } from '../src/context/appContext'
import { HomeContextWrapper } from '../src/context/homeContext'
import {
  GET_PARENT_CATEGORIES,
  GET_PARENT_CATEGORIES_NON_RECURSIVE,
  GET_PRODUCT_CONTAINERS,
} from '../src/operations/queries'
import { APP_SHOP_SETTINGS } from '../src/operations/queries/_appQueries'
import { sortCategories } from '../src/utilityFunctions/categoryUtils'
import { defaultMetaTags } from '../src/utilityFunctions/metaUtils/defaultMetaTags'
import { useShopSettingsInit } from '../src/utilityFunctions/useShopSettingsInit'
// ---- didnt improve mutch...
// const HomePageModule = dynamic(() =>
//   import('../src/components/Modules/HomePageModule'),
// )

// const HomePageModule = dynamic(
//   () => import("../src/components/Modules/HomePageModule"),
//   {
//     ssr: false,
//     loading: () => <p>loading...</p>,
//   }
// );

const Home = ({
  categoriesData,
  data,
  locale,
  locales,
  defaultLocale,
  _nextI18Next,
  shopSettingsData,
  productContainersData,
  ...props
}) => {
  const { t, i18n } = useTranslation(['common'])
  const { initialI18nStore } = _nextI18Next

  const shopSettings = shopSettingsData
    ? { ...shopSettingsData.data.findFirstShopSetting }
    : null
  useShopSettingsInit(shopSettings) // Happens too late

  if (!shopSettingsData) {
    return <p>Shop settings not found</p>
  }

  if ( productContainersData?.sortedProductContainers?.length < 1) {
    const shopSettings = { ...shopSettingsData.data.findFirstShopSetting }
    return (
      <AppContextWrapper locale={locale} shopSettings={shopSettings}>
        <Layout categoriesData={categoriesData} locale={locale}>
          <Container maxWidth="xl">
            {/* <NotFound /> */}
            <p>You have no products</p>
          </Container>
        </Layout>
      </AppContextWrapper>
    )
  }

  // ===== USER AGENT ==== information about incoming user
  // We use this info to determine if its Safari (as it doesnt support webp images)
  let ua
  // if (props.uaString) {
  //   // ---- NO INFO HERE ------
  //   ua = parse(props.uaString);
  // } else {
  //   ua = parse(window.navigator.userAgent);
  // }

  return (
    <>
      {defaultMetaTags({
        shopSettingsData,
        locale,
        locales,
        defaultLocale,
        initialI18nStore,
      })}

      <AppContextWrapper locale={locale} shopSettings={shopSettings}>
        <HomeContextWrapper
          data={{ data }}
          locale={locale}
          shopSettings={shopSettings}
        >
          <Layout categoriesData={categoriesData} locale={locale}>
            <HomePageModule
              data={data}
              locale={locale}
              shopSettings={shopSettings}
              productContainersData={productContainersData}
            />
          </Layout>
        </HomeContextWrapper>
      </AppContextWrapper>
    </>
  )
}

export async function getStaticProps({
  params,
  locale,
  locales,
  defaultLocale,
  req,
}) {
  // const startTime = new Date()
  console.log('starting index page getStaticProps')

  const apolloClient = initializeApollo()
  let orderBy = null,
    where = null
  const filterByLanguage = {
    single_product: {
      product_profiles: { some: { language: { equals: locale } } },
    },
    advanced_product: {
      advanced_product_profiles: {
        is: { some: { language: { equals: locale } } },
      },
    },
  }
  //!THIS SHOULD BE THE ONLY SOURCE OF TRUTH, ONLY CHANGE THIS NUMBER
  const numberOfProductsToTake: number = 32

  // const categoriesData = await apolloClient.query({
  //   query: GET_PARENT_CATEGORIES,
  //   variables: {
  //     language: locale,
  //   },
  // });

  // const timeAfterCategories =
  //   Math.abs(new Date().getTime() - startTime.getTime()) / 1000
  // console.log('index timeAfterCategories ->', timeAfterCategories)

  // console.log('before shop settings :>> ')

  let shopSettingsData = null
  let categoriesData = null
  let productContainersData = null
  try {
    shopSettingsData = await apolloClient.query({
      query: APP_SHOP_SETTINGS,
      variables: {
        // SHOP_ID,
        isHomepage: true,
        isProductPage: false,
      },
    })

    const shopSettings = shopSettingsData
      ? { ...shopSettingsData?.data?.findFirstShopSetting }
      : null

    // THIS NEEDS TO MATCH REVALIDATE.TS IN THE BACKEND
    switch (shopSettings.homepage_product_sort) {
      case 'DISCOUNTED_PRODUCTS':
        where = {
          ...filterByLanguage,
          discount: {
            startDate: {
              lte: new Date().toISOString(),
            },
            expiryDate: {
              gte: new Date().toISOString(),
            },
            active: {
              equals: true,
            },
          },
        }

        break
      case 'NEWEST_PRODUCTS':
        orderBy = {
          createdAt: 'desc',
        }

        break
      case 'HIGHEST_SELLING_PRODUCTS':
        //TODO: IMPORTANT this needs productContainers to be able to match with orderItem
        orderBy = {
          product_position_in_store: 'asc',
        }
        break
      case 'SVP':
        // orderBy = {
        //   product_position_in_store: 'asc',
        // }
        orderBy = {
          SVP: 'desc',
        }

        break

      default:
        break
    }

    // const timeAfterafterShopSettings =
    //   Math.abs(new Date().getTime() - startTime.getTime()) / 1000
    // // console.log('timeAfterafterShopSettings ->', timeAfterafterShopSettings)

    // console.log('categoriesData :>> ', categoriesData)
  } catch (err) {
    console.log('Shop settgins error -> ', err)
  }

  try {
    productContainersData = await apolloClient.query({
      query: GET_PRODUCT_CONTAINERS,
      variables: {
        take: numberOfProductsToTake,
        orderBy,
        where,
        loadMore: false,
      },
    })

    // --- CATEGORY =============
    // const categoriesData = await apolloClient.query({
    //   query: GET_PARENT_CATEGORIES,
    //   variables: {
    //     language: locale,
    //   },
    // })
    const categoriesDataQuery = await apolloClient.query({
      query: GET_PARENT_CATEGORIES_NON_RECURSIVE,
      variables: {
        language: locale,
      },
    })
    let allCategories = [
      ...JSON.parse(JSON.stringify(categoriesDataQuery.data.findManyCategory)),
    ]
    const sortedByMenuOrder = allCategories.sort(
      (a, b) => a.menu_order - b.menu_order,
    )
    const sortedCategories = sortCategories(sortedByMenuOrder)
    // const flatCategories = flattenCategories(sortedCategories)
    categoriesData = { ...JSON.parse(JSON.stringify(categoriesDataQuery)) }
    categoriesData.data.findManyCategory = sortedCategories

    // ---------------- END  CATEGORY =============
  } catch (err) {
    console.log('Error in Homepage Products ->', err)
  }

  // console.log('shopSettingsData :>> ', shopSettingsData)

  // const endTime = new Date()
  // console.log('ending index page getServerSideProps')

  // const timeDiff = Math.abs(endTime.getTime() - startTime.getTime()) / 1000 // Converting to seconds

  // console.log('Time -->', timeDiff)

  let sortedProductContainersData
  if (productContainersData) {
    const { sortedProductContainersData: sortedProductContainersData2 } =
      productContainersData.data
    sortedProductContainersData = sortedProductContainersData2
  }

  // console.log('productContainersData :>> ', productContainersData)
  // console.log('sortedProductContainersData :>> ', sortedProductContainersData)

  return {
    props: {
      // uaString: req.headers["user-agent"],
      locale,
      locales,
      defaultLocale,
      shopSettingsData,
      categoriesData,
      productContainersData: {
        ...sortedProductContainersData,
        filters: {
          orderBy,
          where,
        },
        take: numberOfProductsToTake,
      },
      ...(await serverSideTranslations(locale, ['common'])),
    },
    revalidate: 200, // In seconds
  }
}

export default Home
